<template>
  <header>
    <h1 class="text-center text-xl md:text-2xl m-12"><a v-bind:href="link">{{ name }}</a></h1>
    <!-- <nav class="">
      <ul>
        <li v-for="(lists ,index) in gnavi" v-bind:key="index"><a v-bind:href="lists.link">{{ lists.list }}</a></li>
      </ul>
    </nav> -->
  </header>
</template>

<script>
export default {
  name: 'Header',
  props: {
    name: {
      type: String,
      default: "質預かり金額シミュレーター"
      },
    link: {
      type: String,
      default: './'
    }
  },
  data(){
    return{
      gnavi: [
        {list: 'top', link: './'},
        {list: 'about', link: './about/'},
        {list: 'contact', link: './contact'}
      ]
    }

  }
}
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
header nav{
  height: 60px;
}
header nav ul{
  display: flex;
  justify-content: space-evenly;
  flex-wrap: wrap;
  list-style-type: none;
}
header nav ul li{
  line-height: 60px;
}
</style>
