<template>
  <footer class="mt-20">
    <div class="text-center">
      <!-- <img src="../assets/ueda_logo.svg" alt="質ウエダ" width="120" height="88" class="pb-6"> -->
        <p>Shichi Ueda&copy;All Rights Reserved</p>
      </div><!-- /.copy -->
  </footer>
</template>

<script>
export default {
  name: "Footer",
  props: {
    msg: String
  }
}
</script>

<style scoped>
/* footer{
  width: 100%;
  position: fixed;
  bottom: 0;
} */
</style>