<template>
  <div class="sm:container mx-auto">
    <div class="main">
      <div class="w-11/12 md:w-3/4 bg-green-100 p-5 mt-6 mx-auto rounded-md calculate">
      <Form>
        <p><label for="price">貸付金額&nbsp;<Field type="text" id="price" name="price" v-model="getPrice" class="w-28 ml-4" rules="required|numeric" />円</label><error-message name="price"></error-message></p>
        <p class="mt-3"><label for="period">貸付期間&nbsp;<Field type="text" name="period" id="period" v-model="getPeriod" class="w-10 ml-4" rules="required|numeric|min_value:0" />ヶ月</label><error-message name="period"></error-message></p>
      </Form>
        <p class="mt-6"><label for="date">貸付日<Datepicker v-model="date" locale="ja" :format="format" cancelText="閉じる" selectText="選択する" :enableTimePicker="false"  class="w-48 ml-7" /></label></p>

        <div class="print-division-mt">
          <p v-cloak class="mt-3">お利息は<span class="text-3xl font-bold text-green-600">{{ getInterest }}円</span>(1ヶ月)です。<span class="text-xs inline result" v-bind:class="{show}">※1</span></p>
          <p v-cloak v-bind:class="{show}" class="result"><span class="text-3xl font-bold text-green-600">{{ period }}ヶ月後</span>に質受けする時にはトータルで<span class="text-3xl font-bold text-green-600">{{ getRedeem }}円</span>が必要となります。</p>
          <div v-bind:class="{show}" class="result w-full text-sm bg-white p-2 mt-6 division">
            <ul>
              <li class="mb-4">※1 お利息の日割り計算は行っておりませんので、質入れの翌日から1ヶ月分のお利息が発生します。</li>
              <li>※2 最初の3ヶ月目まではお利息を入れなくても質流れはしませんが、3ヶ月を超えたら、それ以降は最低1ヶ月分/月のお利息を入れないと質流れとなります。</li>
            </ul>
          </div>
        </div><!-- /.print-division-mt -->
        <p class="mt-12">
          <button v-on:click="clearTotal" class="btn block lg:w-80 mx-auto">クリア</button>
        </p>

        <div v-bind:class="{show}" class="w-full flex flex-wrap justify-evenly mt-10 result">

          <h2 class="text-center text-xl">お利息と質受けの料金表</h2>
          <table class="list mx-auto mt-6 mb-6 bg-white text-xs md:text-base">
            <tr>
              <th class="font-normal border-table">期間</th>
              <th class="font-normal border-table">貸付金</th>
              <th class="font-normal border-table">毎月のお利息(お利息の累計)</th>
              <th class="font-normal border-table">質受け金額(貸付金+お利息)</th>
              <th class="font-normal border-table">流質期限</th>
            </tr>
            <tr v-for="(list, index) in data" :key="index">
              <td class="border-table">{{list.index}}ヶ月</td>
              <td class="border-table">{{list.price}}円</td>
              <td class="border-table">{{ getInterest }} ({{list.interest}})円</td>
              <td class="border-table">{{list.redeem}}円</td>
              <td class="border-table">{{list.deadYear}}年{{list.deadMonth}}月{{list.deadDay}}日</td>
            </tr>
          </table>

        </div>

      </div><!-- /.w-3/4 bg-slate-100 p-5 mt-6 mx-auto calculate -->
    </div><!-- /.main -->
  </div><!-- /sm:container mx-auto -->

  <!-- <pre>{{ $data }}</pre> -->
</template>

<script>
import Datepicker from 'vue3-date-time-picker';
import 'vue3-date-time-picker/dist/main.css';
import { ref } from 'vue';

import { defineRule, Form, Field, ErrorMessage, configure} from 'vee-validate';
import { required, min_value, numeric } from '@vee-validate/rules';
import { localize } from '@vee-validate/i18n';
import ja from '@vee-validate/i18n/dist/locale/ja.json';

// エラーメッセージを日本語化
localize('ja', ja);

// 使用するルール
defineRule('required', required);
defineRule('min_value', min_value);
defineRule('numeric', numeric);

// エラーメッセージを出す項目の表示名
configure({
  generateMessage: localize('ja', {
    names: {
      price: '金額',
      period: '期間',
    },
  })
});

export default {
  name: "Main",
  props: {},

setup() {
  const date = ref(new Date());
  const format = function(date){
    const day = date.getDate();
    const month = date.getMonth() + 1;
    const year = date.getFullYear();

    return year + "年" + month + "月" + day + "日";
  }

  return {
      date,
      format
  }
},

  /****************/
  /* データ
  /*****************/
  data() {
    return {
      price: '0', // 元金
      under20000: 0.08,
      under50000: 0.05,
      under500000: 0.032,
      over500000: 0.032,
      interest: '', // 利息
      period: '0', // 期間
      redeem: '', // 質受け金額
      isPrice: false,
      isPeriod: false,
      show: false,
      data: [],
      list: {},
    }
  },

  components: {
    Datepicker,
    Form,
    Field,
    ErrorMessage,
  },


  /****************/
  /* コンピューテッド
  /*****************/
  computed: {

    getPrice: {
      get(){
        return this.price
      },
      set(value){
        this.price = value
        if(this.validateIntrger(value)){
          this.truePrice()
        }else{
          this.falsePrice()
        }
        this.isShow()
      }
    },

    getPeriod: {
      get(){
        return this.period
      },
      set(value){
        this.period = value
        if(this.validateIntrger(value)){
          this.truePeriod()
        }else{
          this.falsePeriod()
        }
        this.isShow()
      }
    },

    getInterest() { // 利息の計算
      let interest = this.returnInterest();
      if(!this.validateIntrger(interest)){
        return
      }
      return interest.toLocaleString();
    },

    getRedeem(){ // 質受け金額
      this.createRedeem();
      this.createList();
      return this.redeem.toLocaleString();
    },

  },

  /****************/
  /* メソッド
  /*****************/
  methods: {

    validateIntrger(value){ // 数値かどうか正規表現で判定
      let regexp = new RegExp(/^\d*$/);
      let result = regexp.test(value);
      if(value !== '' && result === true){
        return true
      }else{
        return false
      }
    },

    truePrice(){
      this.isPrice = true
    },

    falsePrice(){
      this.isPrice = false
    },

    truePeriod(){
      this.isPeriod = true
    },

    falsePeriod(){
      this.isPeriod = false
    },

    isShow(){
      if(this.isPrice && this.isPeriod){
        this.show = true
      }else{
        this.show = false
      }
    },

    clearTotal() { // クリア
      this.price = '0'
      this.period = '0'
      this.redeem = ''
      this.list = {}
      this.data = []
      this.show = false
      this.isPrice = false
      this.isPeriod = false
      let initDay = new Date()
      this.date = initDay
    },

    // usePolyfill(){
    //   Number.isNaN = Number.isNaN || function(value) {
    //   return typeof value === "number" && value !== value;
    //   }
    // },

    returnInterest() { // 利息の計算

      if(!this.validateIntrger(this.price) || this.price === ''){ // 数値でないor空
        this.interest = 0
      }

      if (this.price < 20000) {
        this.interest = Math.floor(this.price * this.under20000)
        return this.interest
      }

      if (this.price >= 20000 && this.price < 50000) {
        this.interest = Math.floor(this.price * this.under50000)
        return this.interest
      }

      if (this.price >= 50000 && this.price < 500000) {
        this.interest = Math.floor(this.price * this.under500000)
        return this.interest
      }

      if (this.price >= 500000) {
        this.interest = Math.floor(this.price * this.over500000)
        return this.interest
      }
    },

    createRedeem(){ // トータル返済金額
      if( !this.validateIntrger(this.price) || !this.validateIntrger(this.period)){
        return
      }else{
        this.redeem = (parseInt(this.price) + parseInt(this.interest) * parseInt(this.period))
      }
    },

    createList(){
        this.list = {};
        this.data = [];

        if(!this.validateIntrger(this.price) || !this.validateIntrger(this.period)){
          return;
        }

        for (let i = 1; i <= this.period; i++) {
          this.list['index'] = i
          this.list['price'] = parseInt(this.price).toLocaleString()
          this.list['interest'] = parseInt(this.interest * i).toLocaleString()
          this.redeem = parseInt(this.price) + parseInt(this.interest * i)
          this.list['redeem'] = this.redeem.toLocaleString()
          this.list['deadline'] = this.addMonthDate(i)
          this.list['deadYear'] = this.addMonthDate(i).getFullYear()
          this.list['deadMonth'] = this.addMonthDate(i).getMonth() + 1
          this.list['deadDay'] = this.addMonthDate(i).getDate()
          this.data.push(this.list)
          this.list = {}
        }
        return this.data
    },

    addMonthDate(n){ // nヶ月後の日付を取得する
      const thisYear = this.date.getFullYear()
      const thisMonth = this.date.getMonth() + 1
      let thisDay = this.date.getDate()
      let addMonth = thisMonth + n // nヶ月後
      let endDate = endOfMonth( thisYear, addMonth ) // +n月の最終日
      if( thisDay > endDate ){ // 今日の日付がn月の最終日より大きかったら
        thisDay = endDate
      // }else{ // 今日の日付がn月の最終日より小さかったら
        // thisDay = thisDay - 1;
      }
      let nMonthDate = new Date( thisYear, addMonth - 1, thisDay)
      return nMonthDate

      function endOfMonth( year, month ){
        let endDate = new Date( year, month, 0 ) // n月の最終日
        return endDate.getDate()
      }
    },
  }
};
</script>

<style scoped>
span[role="alert"]{
  display: inline-block;
  background: #F87171;
  color: #fff;
  padding: .2em;
  margin-left: 1em;
  border-radius: 6px;
  position: relative;
}

span[role="alert"]::before{
  display: block;
  position: absolute;
  top: -1.5em;
  content: "※エラーがあります。";
  color: #F87171;
  font-weight: bold;
  font-size: .6rem;
}

.print-division-mt{
  margin-top: 2rem;
}

.result{
  display: none;
}

.show{
  display: inline-block;
}

.result table tr th{
  font-weight: normal;
}

table.list tr:nth-child(2),
table.list tr:nth-child(3),
table.list tr:nth-child(4){
  background: #FECACA;
}

table.list tr:nth-child(2) td:nth-child(1)::after{
  display: inline-block;
  content: "※1";
  font-size: 0.75rem;
  margin-left: .5rem;
}

table.list tr:nth-child(2) td:nth-child(5)::after,
table.list tr:nth-child(3) td:nth-child(5)::after,
table.list tr:nth-child(4) td:nth-child(5)::after{
  display: inline-block;
  content: "※2";
  font-size: 0.75rem;
  margin-left: .5rem;
}

.dp__main{
  display: inline-block;
}

/* .dp__select{
  color: rgba(4, 120, 87) !important;
} */
</style>